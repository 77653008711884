import React from 'react'
import styled, {keyframes} from 'styled-components'
import Progress from 'components/progress'
import Timeline from 'sections/about/parts/Timeline'

class TabsPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "skills"
        }
    }

    render() {
        const TabContainer = styled.div`
            min-height: 400px;
            margin-top: 20px;
            @media (max-width:767px) {
                margin-top: 50px;
                padding: 0 20px;
            }
        `;
        const TabSelectors = styled.div`
            display: flex;
        `;

        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `;
        const TabSelector = styled.button`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            margin: 0 10px 0 0;
            border-bottom: 2px solid #fff;
            transition: .5s;
            &:hover, &.active {
                animation: ${ColorAnimation} 10s infinite alternate;
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        `;

        const Tabs = styled.div`
            margin-top: 20px;
        `;

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `;

        const Tab = styled.div`
            display: none;
            animation: ${Fade} 1s forwards;
        `;

        return (
            <TabContainer>
                <TabSelectors>
                    <TabSelector className={this.state.tab === "skills" ? "active" : ""}
                                 onClick={() => this.setState({tab: "skills"})}>
                        Skills
                    </TabSelector>
                    <TabSelector className={this.state.tab === "experience" ? "active" : ""}
                                 onClick={() => this.setState({tab: "experience"})}>
                        Experience
                    </TabSelector>
                    <TabSelector className={this.state.tab === "research experience" ? "active" : ""}
                                 onClick={() => this.setState({tab: "research experience"})}>
                        Research
                    </TabSelector>
                    <TabSelector className={this.state.tab === "education" ? "active" : ""}
                                 onClick={() => this.setState({tab: "education"})}>
                        Education
                    </TabSelector>
                </TabSelectors>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "skills" ? "block" : "none"
                    }}>
                        <Progress value={100} text="Angular"/>
                        <Progress value={90} text="JavaScript"/>
                        <Progress value={90} text="TypeScript"/>
                        <Progress value={90} text="NestJS"/>
                        <Progress value={80} text="PostgresSQL"/>
                        <Progress value={70} text="Java"/>
                        <Progress value={70} text="C#"/>
                    </Tab>
                </Tabs>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "experience" ? "block" : "none"
                    }}>
                        <Timeline data={{
                            "2016 - 2018": {
                                title: "Automation Developer",
                                institution: " Israeli Air Force (Integration Unit) ",
                                description: "Developed unique QA automation tools based on C# & Python to achieve new testing capabilities and improve existing ones."
                            },
                            "2018 - 2018": {
                                title: "Front End Developer ",
                                institution: "OFEK 324",
                                description: "Developed medium-scale WEB application using Angular 6, RxJS and several JavaScript libraries."
                            },
                            "2018 - 2020": {
                                title: "Full Stack Developer ",
                                institution: "OFEK 324",
                                description: "Developed medium-scale WEB application using Java, Angular 7, RxJS 6, ngrx and several JavaScript libraries.\n" +
                                    "Developed Restful Apis using Dropwizard and Javelin.\n"  +
                                    "Developed small-scale WEB applications using Ag-grid.\n" +
                                    "Developed small-scale WEB application using React."
                            },
                            "2019 - 2021": {
                                title: "Backend Architecture",
                                institution: "Startup",
                                description: "Plan and develop medium-to-large-scale back-end architecture, using microservices methodologies and mono repository methodology implemented with Nx by Nrwl, NestJS, Firebase, and PostgreSQL." +
                                    "\nManage time for the development and deployment process."
                            },
                            "2020 - Now": {
                                title: "Software Architecture",
                                institution: "OFEK 324",
                                description: "Plan and implement large architectural and engineering infrastructure changes in a real-time web system towards the first deployment.\n" +
                                    "Mentor a developing division of 15, Serve as a senior engineering and architecture consultant.\n"
                            }
                        }
                        }
                        />
                    </Tab>
                    <Tab style={{
                        display: this.state.tab === "research experience" ? "block" : "none"
                    }}>
                        <Timeline data={{
                            "2020": {
                                title: "On Unimodality of independence polynomial of trees",
                                institution: "HIT - Holon Institute of Technology",
                                description: "Presented in the 52rd Southeastern International Conference on Combinatorics, Graph Theory, and Computing (SEICCGTC 2021). Authors: Ron Yosef, Matan Mizrachi, Ohr Kadrawi."
                            },
                            "2021": {
                                title: "A Linear Algorithm for Computing Independence Polynomials of Trees",
                                institution: "HIT - Holon Institute of Technology",
                                description: "Presented in the 28th British Combinatorial Conference (BCC 2021)." +
                                    "Authors: Ohr Kadrawi, Vadim E. Levit, Ron Yosef, Matan Mizrachi."
                            },
                            "2022": {
                                title: "VASR: Visual Analogies of Situation Recognition",
                                institution: "The Hebrew University of Jerusalem",
                                description: "Accepted to The Thirty-Seventh AAAI Conference on Artificial Intelligence (AAAI-23). Authors: Yonatan Bitton, Ron Yosef, Eli Strugo, Dafna Shahaf, Roy Schwartz, Gabriel Stanovsky."
                            },
                            "2022 ": {
                                title: "WinoGAViL: Gamified Vision and Language Association Benchmark",
                                institution: "The Hebrew University of Jerusalem",
                                description: "Feature Paper Presentation at NeurIPS 2022 Datasets and Benchmarks Track. Authors: Yonatan Bitton*, Nitzan Bitton Guetta*, Ron Yosef, Yuval Elovici, Mohit Bansal, Gabriel Stanovsky, Roy Schwartz."
                            },
                            "2023 ": {
                                title: "IRFL: Image Recognition of Figurative Language",
                                institution: "The Hebrew University of Jerusalem",
                                description: ""
                            }
                        }
                        }
                        />
                    </Tab>
                </Tabs>
            </TabContainer>
        )
    }
}

export default TabsPart
